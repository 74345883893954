import React from "react";
import "/node_modules/primeflex/primeflex.css";

import { NavLink } from "react-router-dom";
import { BsGlobe2 } from "react-icons/bs";
import { BsBank } from "react-icons/bs";

const Frontpage = () => {
  return (
    <>
      <div className="flex-column justify-content-center w-full p-5 pt-2 pb-8">
        <img
          className="h-8rem w-full"
          src={"images/intrasettle_White.svg"}
          alt="logo"
        />
      </div>

      <div className="flex md:justify-content-around md:flex-row flex-column justify-content-center m-8 ">
        <div className="md:w-2 m-5 ">
          <NavLink to="/cb-login">
            <div className="card mb-0 border-1 border-100 bg-gray-800 bg-gray-800  transition-colors transition-duration-500  hover:bg-gray-900 text-white hover:text-gray-900">
              <div className="flex flex-column align-items-center mb-3">
                <div>
                  <span className="block text-900 font-medium text-2xl mb-3">
                    CENTRAL BANK
                  </span>
                </div>
                <div
                  className="flex align-items-center justify-content-center bg-gray-900 border-round"
                  style={{ width: "8rem", height: "7rem" }}
                >
                  <BsGlobe2 className="text-8xl text-center text-blue-500" />
                </div>
              </div>
              <div className="text-center">
                <NavLink to="/cb-login">
                  <span className="text-green-500 text-2xl font-medium">
                    LOGIN
                  </span>
                </NavLink>
              </div>
            </div>
          </NavLink>
        </div>

        <div className="md:w-2 m-5 ">
          <NavLink to="/wb-login">
            <div className="card mb-0 border-1 border-100 bg-gray-800 bg-gray-800  transition-colors transition-duration-500  hover:bg-gray-900 text-white hover:text-gray-900">
              <div className="flex flex-column align-items-center mb-3">
                <div>
                  <span className="block text-900 font-medium text-2xl mb-3">
                    WHOLESALE BANK
                  </span>
                </div>
                <div
                  className="flex align-items-center justify-content-center bg-gray-900 border-round"
                  style={{ width: "8rem", height: "7rem" }}
                >
                  <BsBank className="text-7xl text-yellow-600" />
                </div>
              </div>
              <div className="text-center">
                <span className="text-green-500 text-2xl font-medium">
                  LOGIN
                </span>
              </div>
            </div>
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default Frontpage;
