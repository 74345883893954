import React, { useState, useEffect } from "react";
import { NavLink, Route, useHistory } from "react-router-dom";
import { Menubar } from "primereact/menubar";
import { BsGlobe2 } from "react-icons/bs";
import { TabMenu } from "primereact/tabmenu";
import { useToken } from "../../components/App/useToken";
import CBHome from "../../components/CBtabmenu/CBHome";
import Admincentral from "../Admin/Admincentral";
import CbMenbercontrols from "../../components/CBtabmenu/CbMenbercontrols";
import CBIncomingRequest from "../../components/CBtabmenu/CBIncomingRequest";
import CBTreasuryDashboard from "../../components/CBtabmenu/CBTreasuryDashboard";

import CBRefernceModal from "../../components/CBtabmenu/CBRefernceModal";
import CBVisiblity from "../../components/CBtabmenu/CBVisiblity";

const CentralBank = () => {
  const history = useHistory();
  //  if(useToken.getUser().marker)
  const usetoken = new useToken();
  const [user, setUser] = useState(usetoken.getUser());

  const Redirectnonconfigured = () => {
    if (!user || user.marker == "") {
      alert("The system is not configured");
      history.push("/");
    }
  };

  useEffect(() => {
    gotoproperrole();
  }, [user.role]); //

  const gotoproperrole = async () => {
    if (user.role == "Wholesale bank") {
      alert("Logged in as Wholesale bank, going to Wholesale bank ");
      history.push("/wholesale-bank-one");
    }
  };

  const wizardItems = [
    {
      label: "HOME",
      icon: "pi text-2xl text-blue-500 pi-fw pi-th-large",
      command: () => history.push("/central-bank"),
    },
    {
      label: "CBDC ADMIN",
      icon: "pi text-2xl text-blue-500 pi-fw pi-shield",
      command: () => history.push("/central-bank/cbdc-admin"),
    },
    {
      label: "CENTRAL BANK CONTROL ",
      icon: "pi text-3xl text-blue-500 pi-fw pi-user-edit",
      command: () => history.push("/central-bank/centralbank-controls"),
    },

    {
      label: "TREASURY DASHBOARD",
      icon: "pi text-2xl text-blue-500  pi-fw pi-chart-pie",
      command: () => history.push("/central-bank/treasury-dashboard"),
    },
  ];

  Redirectnonconfigured();

  return (
    <div>
      <Menubar
        start={
          <NavLink to="/">
            <img
              src={"https://intrasettle.com/static/img/intrasettle_white.svg"}
              alt="logo"
              style={{ width: "12rem" }}
            />
          </NavLink>
        }
        end={
          <>
            <NavLink to="/cb-login">
              <BsGlobe2 className="text-3xl mr-3 text-blue-500" />
              <span className="text-2xl text-white">
                The {user.organization} , powered by Intrasettle
              </span>
            </NavLink>
          </>
        }
        className="pt-4 pb-4 layout-topbar"
      />
      <div className=" col-12  justify-content-around pt-8 px-7">
        <TabMenu
          model={wizardItems}
          style={{ fontSize: "1.3rem" }}
          className="pt-4 pb-1 card text-xl"
        />
      </div>
      <Route exact path={"/central-bank"} component={CBHome} />
      <Route path={"/central-bank/cbdc-admin"} component={Admincentral} />
      <Route
        path={"/central-bank/member-controls"}
        component={CbMenbercontrols}
      />
      <Route
        path={"/central-bank/centralbank-controls"}
        component={CBIncomingRequest}
      />

      <Route
        path={"/central-bank/treasury-dashboard"}
        component={CBTreasuryDashboard}
      />
      <Route
        path={"/central-bank/reference-modals"}
        component={CBRefernceModal}
      />
      <Route
        path={"/central-bank/visibility-reissuance"}
        component={CBVisiblity}
      />
    </div>
  );
};

export default CentralBank;
