import React, { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Route, useHistory } from "react-router-dom";

import { useToken } from "../App/useToken";
import { LoginService } from "../devlogin/LoginService";

import { IssuanceService } from "../CBtabmenu/CBHome/IssuanceService";

export default function Createassets({ setToken }) {
  const Entityinfo = {
    email: "",
    username: "",
    urlname: "",
    role: "",
    organization: "",
    accountid: "",
    entityaccountnumber: "",
    centralaccountnumber: "",
    entityemail: "",
    entityname: "",
    systemid: "",
    issuerid: "",
    funderid: "",
    entityid: "",
    marker: "",
  };

  const Sendinfo = {
    systemid: "",
    toaccountnumber: "",
    symbol: "",
    amount: "",
  };

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const [sendinfo, setSendinfo] = useState(Sendinfo);
  const [entityinfo, setEntityinfo] = useState(Entityinfo);

  const [error, setError] = useState("");
  const [symbol, setSymbol] = useState("");
  const [newsymbol, setNewsymbol] = useState("");
  const [amount, setAmount] = useState(10000);

  const [role, setRole] = useState("");
  const [decimal, setDecimal] = useState(1);
  const [retaillimit, setRetaillimit] = useState(1000);
  const [fundsneeded, setFundsneeded] = useState(0);
  const [assets, setAssets] = useState();
  const [fundassetbalance, setFundassetbalance] = useState({
    confirmed: 0,
    unconfirmed: 0,
  });
  const [totalassets, setTotalassets] = useState(0);
  const [maxdisabled, setMaxdisabled] = useState(false);
  const [maximumessage, setMaximumessage] = useState("");

  const loginservice = new LoginService();

  const history = useHistory();

  const roles = ["Central bank", "Wholesale bank", "Exchange"];
  const decimals = [0, 1, 2];

  const issuanceservice = new IssuanceService();
  const usetoken = new useToken();

  useEffect(() => {
    var user = usetoken.getUser();
    setEntityinfo(user);
    getassets1();
  }, []); //

  useEffect(() => {
    gotoproperrole();
  }, [entityinfo.role]); //

  const gotoproperrole = async () => {
    if (entityinfo.role == "Wholesale bank") {
      alert("Logged in as Wholesale bank, going to Wholesale bank admin");
      history.push("/wholesale-bank-one/cbdc-admin");
    }
  };

  const getdata1 = async () => {
    const tokendata = await loginservice.getlatestuser();
    if (tokendata.token) {
      usetoken.saveToken(tokendata);
      setEntityinfo(tokendata.user);
    }
    getassets1();

    const fundassetbalance = await loginservice.getfundassetbalance();
    if (fundassetbalance) {
      setFundassetbalance({
        confirmed: fundassetbalance.confirmed,
        unconfirmed: fundassetbalance.unconfirmed,
      });
    }
  };

  const getassets1 = async () => {
    var assets = await issuanceservice.getassets();
    var yy = assets.map((kk) => {
      var { disabled, ...newResponse } = kk;
      newResponse.disable = kk.disabled;
      return newResponse;
    });
    var zz = yy.filter((pp) => {
      if (pp.disable != "yes") return true;
    });
    setTotalassets(zz.length);
    if (zz.length > 0) {
      setMaximumessage("Maximum 1 type of asset (testnet)");
      setMaxdisabled(true);
    } else {
      setMaximumessage("");
      setMaxdisabled(false);
    }
    setAssets(zz);
  };

  const enteramount = async (amt) => {
    if (amt > 1000000) amt = 1000000;
    setAmount(amt);

    setFundsneeded(amt * 10 ** decimal);
  };

  const enterdecimal = async (decimal) => {
    setDecimal(decimal);

    console.log(amount * 10 ** decimal);
    setFundsneeded(amount * 10 ** decimal);
  };

  const enterretaillimit = async (amt) => {
    if (amt > 500000) amt = 500000;
    setRetaillimit(amt);
  };

  const createasset = async () => {
    if (fundassetbalance.confirmed < fundsneeded) {
      alert("Not sufficient funds for required asset creation");
      return;
    }
    try {
      var data = {
        symbol: newsymbol,
        decimal: decimal,
        retaillimit: retaillimit,
        supply: amount,
      };
      const tokendata = await loginservice.createasset(data);
      setError("Success ");
    } catch (err) {
      setError("Update failed " + err);
    }
  };

  return (
    <div className="col-12 ">
      <div className="card p-fluid">
        <h5 className="text-3xl text-center uppercase my-3">
          Central bank create assets
        </h5>
        <div className="field text-2xl mt-5">
          Logged user:
          <span className="text-cyan-200">
            {" "}
            {entityinfo ? entityinfo.email : "Not logged in"}
          </span>
        </div>
        <div className="field text-2xl">
          <label>
            {" "}
            Organization:{" "}
            <span className="text-cyan-200">
              {entityinfo ? entityinfo.organization : "NA"}{" "}
            </span>
          </label>
        </div>

        <div className="field text-2xl">
          <p>
            {" "}
            Enabled assets with entity :{" "}
            <span className="text-cyan-200">{totalassets}</span>
          </p>
        </div>

        <div className="field text-2xl">
          <p>
            {" "}
            Funds confirmed :{" "}
            <span className="text-cyan-200">{fundassetbalance.confirmed}</span>
          </p>
        </div>
        <div className="field text-2xl">
          <p>
            {" "}
            Funds unconfirmed:{" "}
            <span className="text-cyan-200">
              {fundassetbalance.unconfirmed}
            </span>
          </p>
        </div>

        <div className="field text-2xl">
          <p>
            {" "}
            Funds needed: <span className="text-cyan-200">{fundsneeded}</span>
          </p>
        </div>

        <div className="formgrid grid">
          <div className="field col-12 lg:col-6 text-2xl">
            <label htmlFor="newasset">
              New asset: <span className="text-cyan-200">{newsymbol}</span>
            </label>
            <InputText
              id="newasset"
              type="text"
              placeholder="Digital_sym"
              onChange={(e) => setNewsymbol(e.target.value)}
              style={{ height: "4rem", fontSize: "1.6rem" }}
            />
          </div>
          <div className="field col-12 lg:col-6 text-2xl">
            <label htmlFor="amount">
              Total Assets (Max 10000 in testnet) :{" "}
              <span className="text-cyan-200"> {amount}</span>
            </label>
            <InputText
              id="amount"
              type="number"
              placeholder="amount"
              onChange={(e) => enteramount(e.target.value)}
              style={{ height: "4rem", fontSize: "1.6rem" }}
            />
          </div>
        </div>

        <div className="formgrid grid">
          <div className="field col-12 lg:col-6 text-2xl">
            <label htmlFor="retaillimit">
              Limit/per bank:{" "}
              <span className="text-cyan-200">{retaillimit}</span>
            </label>
            <InputText
              id="retaillimit"
              type="number"
              placeholder="Limit per bank"
              onChange={(e) => enterretaillimit(e.target.value)}
              style={{ height: "4rem", fontSize: "1.6rem" }}
            />
          </div>
          <div className="field col-12 lg:col-6 text-2xl">
            <label htmlFor="decimals">
              Decimal: <span className="text-cyan-200">{decimal}</span>
            </label>
            <Dropdown
              value={decimal}
              options={decimals}
              onChange={(e) => setDecimal(e.target.value)}
              placeholder="Select the decimal "
              id="decimals"
              className="p-2 font-semibold  bg-gray-100"
            />
          </div>
        </div>
      </div>

      <div className="field text-2xl flex  align-items-center  justify-content-between">
        <div>
          <div className="flex  flex-column  ">
            <span className="text-pink-500 ml-4 text-center">
              {maximumessage}
            </span>
            <span className="text-red-500 ml-4 text-center">{error}</span>
          </div>
        </div>
        <div>
          <div className="flex flex-column mr-3">
            <span className="text-red-500 ml-4">{error}</span>
          </div>
        </div>
      </div>
      <div className="field text-2xl flex align-items-center justify-content-between">
        <div>
          <div className="flex flex-column">
            <Button
              label="Create Asset"
              onClick={() => createasset()}
              className=" m-3 text-2xl p-button-success"
              disabled={maxdisabled}
            />
          </div>
        </div>

        <div>
          <div className="flex flex-column mr-5">
            <Button
              label="Refresh"
              onClick={() => getdata1()}
              className=" m-3 text-2xl"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
