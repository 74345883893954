import React, { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { Route, useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import { useToken } from "../App/useToken";
import { LoginService } from "../devlogin/LoginService";

export default function Centralbanksetup({ setToken }) {
  const Entityinfo = {
    email: "",
    username: "",
    urlname: "",
    role: "",
    organization: "",
    accountid: "",
    entityaccountnumber: "",
    centralaccountnumber: "",
    entityemail: "",
    entityname: "",
    systemid: "",
    issuerid: "",
    funderid: "",
    entityid: "",
    marker: "",
  };

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const [centralbanks, setCentralbanks] = useState([]);
  const [centralbank, setCentralbank] = useState({});

  const [entryinfo, setEntryinfo] = useState(Entityinfo);
  const [entityinfo, setEntityinfo] = useState(Entityinfo);

  const [error, setError] = useState("");

  const [role, setRole] = useState("");

  const [organization, setOrganization] = useState("");

  const history = useHistory();

  const roles = ["Central bank", "Wholesale bank", "Exchange"];

  const loginservice = new LoginService();
  const usetoken = new useToken();

  useEffect(() => {
    setEmail(centralbank.email);
  }, [centralbank]);

  useEffect(() => {
    getcentralbanks();
    getdata();
  }, []);

  useEffect(() => {
    gotoproperrole();
  }, [entityinfo.role]); //

  const gotoproperrole = async () => {
    if (entityinfo.role == "Wholesale bank") {
      alert("Logged in as Wholesale bank, going to Wholesale bank admin");
      history.push("/wholesale-bank-one/cbdc-admin");
    }
  };

  const getcentralbanks = async () => {
    try {
      const tokendata = await loginservice.getallcentralbanks();
      console.log(tokendata);
      setCentralbanks(tokendata);
    } catch (err) {
      setError("Update failed " + err);
    }
  };

  const login = async () => {
    try {
      const tokendata = await loginservice.loginUser({
        email,

        password,
      });

      if (tokendata.token) {
        usetoken.saveToken(tokendata);
        setEntityinfo(tokendata.user);

        setError("Login success");
        //        history.push('/central-bank')
      } else {
        setError("Login failed");
      }
    } catch (err) {
      setError("Login failed");
    }
  };
  const getdata = async () => {
    const tokendata = await loginservice.getlatestuser();
    if (tokendata.token) {
      usetoken.saveToken(tokendata);
      setEntityinfo(tokendata.user);
    }
  };

  const update = async () => {
    try {
      console.log(entityinfo);

      const tokendata = await loginservice.updateUser(
        entityinfo,
        usetoken.getToken()
      );
    } catch (err) {
      setError("Update failed " + err);
    }
  };

  const configaccount = async () => {
    try {
      const accounts = await loginservice.cbconfigcentralaccounts();
    } catch (err) {
      setError("config account failed " + err);
    }
  };

  const enablecb = async () => {
    const tokendata = await loginservice.enablecb();
  };

  const disablecb = async () => {
    const tokendata = await loginservice.disablecb();
  };

  return (
    <div className="col-12 ">
      <div className="card p-fluid">
        <h5 className="text-3xl text-center uppercase my-3">
          Central Bank Configure
        </h5>
        <div className="field text-2xl mt-5">
          Logged user:{" "}
          <span className="text-cyan-200">
            {" "}
            {entityinfo ? entityinfo.email : "Not logged in"}
          </span>
        </div>
        <div className="field text-2xl">
          <label>
            {" "}
            Organization:{" "}
            <span className="text-cyan-200">
              {entityinfo ? entityinfo.organization : "NA"}
            </span>
          </label>
        </div>

        <div className="formgrid grid">
          <div className="field col-12 lg:col-6 text-2xl">
            <label htmlFor="entityemail">
              Entity email:{" "}
              <span className="text-cyan-200">{entityinfo.entityemail}</span>
            </label>
            <InputText
              id="entityemail"
              type="email"
              onChange={(e) =>
                setEntityinfo({ ...entityinfo, entityemail: e.target.value })
              }
              style={{ height: "4rem", fontSize: "1.6rem" }}
            />
          </div>
          <div className="field col-12 lg:col-6 text-2xl">
            <label htmlFor="urlname">
              URL name:{" "}
              <span className="text-cyan-200">{entityinfo.urlname}</span>
            </label>
            <InputText
              id="urlname"
              type="text"
              onChange={(e) =>
                setEntityinfo({ ...entityinfo, urlname: e.target.value })
              }
              style={{ height: "4rem", fontSize: "1.6rem" }}
            />
          </div>
        </div>
        <div className="formgrid grid">
          <div className="field col-12 lg:col-6 text-2xl">
            <label htmlFor="entityid">
              Entity id:{" "}
              <span className="text-cyan-200"> {entityinfo.entityid}</span>
            </label>
            <InputText
              id="entityid"
              type="text"
              onChange={(e) =>
                setEntityinfo({ ...entityinfo, entityid: e.target.value })
              }
              style={{ height: "4rem", fontSize: "1.6rem" }}
            />
          </div>
          <div className="field col-12 lg:col-6 text-2xl">
            <label htmlFor="enaccnumber">
              Entity account number:{" "}
              <span className="text-cyan-200">
                {" "}
                {entityinfo.entityaccountnumber}
              </span>
            </label>
            <InputText
              id="enaccnumber"
              type="text"
              onChange={(e) =>
                setEntityinfo({
                  ...entityinfo,
                  entityaccountnumber: e.target.value,
                })
              }
              style={{ height: "4rem", fontSize: "1.6rem" }}
            />
          </div>
        </div>
        <div className="formgrid grid">
          <div className="field col-12 lg:col-6 text-2xl">
            <label htmlFor="cenaccnumber">
              Central account number:{" "}
              <span className="text-cyan-200">
                {" "}
                {entityinfo.centralaccountnumber}
              </span>
            </label>
            <InputText
              id="cenaccnumber"
              type="text"
              onChange={(e) =>
                setEntityinfo({
                  ...entityinfo,
                  centralaccountnumber: e.target.value,
                })
              }
              style={{ height: "4rem", fontSize: "1.6rem" }}
            />
          </div>
          <div className="field col-12 lg:col-6 text-2xl">
            <label htmlFor="issuerid">
              Issuerid :{" "}
              <span className="text-cyan-200"> {entityinfo.issuerid}</span>
            </label>
            <InputText
              id="issuerid"
              type="text"
              onChange={(e) =>
                setEntityinfo({ ...entityinfo, issuerid: e.target.value })
              }
              style={{ height: "4rem", fontSize: "1.6rem" }}
            />
          </div>
        </div>
        <div className="formgrid grid">
          <div className="field col-12 lg:col-6 text-2xl">
            <label htmlFor="funderid">
              Funderid :{" "}
              <span className="text-cyan-200"> {entityinfo.funderid}</span>
            </label>
            <InputText
              id="funderid"
              type="text"
              onChange={(e) =>
                setEntityinfo({ ...entityinfo, funderid: e.target.value })
              }
              style={{ height: "4rem", fontSize: "1.6rem" }}
            />
          </div>
          <div className="field col-12 lg:col-6 text-2xl">
            <label htmlFor="systemid">
              Systemid :{" "}
              <span className="text-cyan-200">{entityinfo.systemid}</span>
            </label>
            <InputText
              id="systemid"
              type="text"
              onChange={(e) =>
                setEntityinfo({ ...entityinfo, systemid: e.target.value })
              }
              style={{ height: "4rem", fontSize: "1.6rem" }}
            />
          </div>
        </div>
        <div className="formgrid grid">
          <div className="field col-12 lg:col-6 text-2xl">
            <label htmlFor="entityname">
              Entity name :{" "}
              <span className="text-cyan-200"> {entityinfo.entityname}</span>
            </label>
            <InputText
              id="entityname"
              type="text"
              onChange={(e) =>
                setEntityinfo({ ...entityinfo, entityname: e.target.value })
              }
              style={{ height: "4rem", fontSize: "1.6rem" }}
            />
          </div>
          <div className="field col-12 lg:col-6 text-2xl">
            <label htmlFor="centraladdress">
              Central address :{" "}
              <span className="text-cyan-200">{entityinfo.centraladdress}</span>
            </label>
            <InputText
              id="centraladdress"
              type="text"
              onChange={(e) =>
                setEntityinfo({ ...entityinfo, centraladdress: e.target.value })
              }
              style={{ height: "4rem", fontSize: "1.6rem" }}
            />
          </div>
        </div>
        <div className="formgrid grid">
          <div className="field col-12 lg:col-6 text-2xl">
            <label htmlFor="entitycode">
              Entity code :{" "}
              <span className="text-cyan-200">{entityinfo.entitycode}</span>
            </label>
            <InputText
              id="entitycode"
              type="text"
              onChange={(e) =>
                setEntityinfo({ ...entityinfo, entitycode: e.target.value })
              }
              style={{ height: "4rem", fontSize: "1.6rem" }}
            />
          </div>
          <div className="field col-12 lg:col-6 text-2xl">
            <label htmlFor="entityaddress">
              Entity address :{" "}
              <span className="text-cyan-200">{entityinfo.entityaddress}</span>
            </label>
            <InputText
              id="entityaddress"
              type="text"
              onChange={(e) =>
                setEntityinfo({ ...entityinfo, entityaddress: e.target.value })
              }
              style={{ height: "4rem", fontSize: "1.6rem" }}
            />
          </div>
        </div>
        <div className="formgrid grid">
          <div className="field col-12 lg:col-6 text-2xl">
            <label htmlFor="centraladdress">
              Central address :{" "}
              <span className="text-cyan-200">{entityinfo.centraladdress}</span>
            </label>
            <InputText
              id="centraladdress"
              type="text"
              onChange={(e) =>
                setEntityinfo({ ...entityinfo, centraladdress: e.target.value })
              }
              style={{ height: "4rem", fontSize: "1.6rem" }}
            />
          </div>
          <div className="field col-12 lg:col-6 text-2xl">
            <label htmlFor="marker">
              Marker :{" "}
              <span className="text-cyan-200">{entityinfo.marker}</span>
            </label>
            <InputText
              id="marker"
              type="text"
              onChange={(e) =>
                setEntityinfo({ ...entityinfo, marker: e.target.value })
              }
              style={{ height: "4rem", fontSize: "1.6rem" }}
            />
          </div>
        </div>
        <div className="formgrid grid">
          <div className="field col-12 lg:col-6 text-2xl">
            <label htmlFor="organization">
              Organization :{" "}
              <span className="text-cyan-200">{entityinfo.organization}</span>
            </label>
            <InputText
              id="organization"
              type="text"
              onChange={(e) =>
                setEntityinfo({ ...entityinfo, organization: e.target.value })
              }
              style={{ height: "4rem", fontSize: "1.6rem" }}
            />
          </div>
          <div className="field col-12 lg:col-6 text-2xl">
            <label htmlFor="role">
              Role : <span className="text-cyan-200">{entityinfo.role}</span>
            </label>
            <Dropdown
              id="role"
              type="text"
              placeholder="Select Central bank"
              onChange={(e) =>
                setEntityinfo({ ...entityinfo, role: e.target.value })
              }
              className="p-2 font-semibold  bg-gray-100"
            />
          </div>
        </div>
        <div className="formgrid grid">
          <div className="field col-12 lg:col-6 text-2xl">
            <label htmlFor="cbank">Choose central bank</label>

            <Dropdown
              id="cbank"
              optionLabel="organization"
              value={centralbank}
              options={centralbanks}
              onChange={(e) => {
                setCentralbank(e.target.value);
              }}
              placeholder="Select a bank to enable/disable"
              className="p-2 font-semibold  bg-gray-100"
            />
          </div>

          <div className="field col-12 lg:col-6 text-2xl">
            <label htmlFor="email">
              Email for bank :{" "}
              <span className="text-cyan-200">{centralbank.email}</span>
            </label>
          </div>
        </div>

        <div className="field text-2xl">
          {/* Status:  */}
          <span className="text-pink-500">{error}</span>
          <div className="grid lg:flex align-items-center lg:justify-content-between">
            {/* <label> */}
            <Button
              label="Update"
              onClick={() => update()}
              className="col-12 lg:col m-3 text-2xl"
              disabled={true}
            />
            <Button
              label="Refresh"
              onClick={() => getdata()}
              className="col-12 lg:col m-3 text-2xl"
            />
            <Button
              label="ConfigAccount"
              severity="secondary"
              onClick={() => configaccount()}
              className="col-12 lg:col m-3 text-2xl p-button-secondary"
            />

            <Button
              label="Enable"
              onClick={() => enablecb()}
              className="col-12 lg:col m-3 text-2xl p-button-success"
              disabled={true}
            />

            <Button
              label="Disable"
              onClick={() => disablecb()}
              className="col-12 lg:col m-3 text-2xl p-button-danger"
              disabled={true}
            />

            {/* </label> */}
          </div>
        </div>
      </div>
    </div>
  );
}
