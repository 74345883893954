import React, { useEffect, useState } from "react";
//import { ConsoleLogger } from '../Log/Logger';
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Route, useHistory } from "react-router-dom";

import { useToken } from "../App/useToken";
import { LoginService } from "../devlogin/LoginService";

import { IssuanceService } from "../CBtabmenu/CBHome/IssuanceService";

export default function Cbhealthcheck({ setToken }) {
  const Entityinfo = {
    email: "",
    username: "",
    urlname: "",
    role: "",
    organization: "",
    accountid: "",
    entityaccountnumber: "",
    centralaccountnumber: "",
    entityemail: "",
    entityname: "",
    systemid: "",
    issuerid: "",
    funderid: "",
    entityid: "",
    marker: "",
  };

  const Sendinfo = {
    systemid: "",
    toaccountnumber: "",
    symbol: "",
    amount: "",
  };

  const availableformint = () => {};

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [available, setAvailable] = useState(0);

  const [sendinfo, setSendinfo] = useState(Sendinfo);
  const [entityinfo, setEntityinfo] = useState(Entityinfo);

  const [error, setError] = useState("");
  const [symbol, setSymbol] = useState({});
  const [amount, setAmount] = useState("");

  const [role, setRole] = useState("");
  const [assets, setAssets] = useState([]);

  const [entityid, setEntityid] = useState({
    name: "",
    error: "",
  });

  const [user, setUser] = useState({
    name: "",
    error: "",
  });

  const [centralentity, setCentralentity] = useState({
    name: "",
    error: "",
  });

  const [centralentityaccount, setCentralentityaccount] = useState({
    name: "",
    error: "",
  });

  const [entityaccounttokenuserx, setEntityaccounttokenuserx] = useState({
    name: "",
    error: "",
  });

  const [centralaccountnumber, setCentralaccountnumber] = useState({
    name: "",
    error: "",
  });

  const [centralaccounttokenuserx, setCentralaccounttokenuserx] = useState({
    name: "",
    error: "",
  });

  const loginservice = new LoginService();

  const history = useHistory();

  const roles = ["Central bank", "Wholesale bank", "Exchange"];

  const issuanceservice = new IssuanceService();
  const usetoken = new useToken();
  //  const consolelogger = new ConsoleLogger();

  useEffect(() => {
    var user = usetoken.getUser();
    setEntityinfo(user);
    refresh();
  }, []); //

  useEffect(() => {
    gotoproperrole();
  }, [entityinfo.role]); //

  useEffect(() => {
    if (symbol.issuetype) {
      availableformint();
    }
  }, [symbol.issuetype]); //
  const gotoproperrole = async () => {
    if (entityinfo.role == "Wholesale bank") {
      alert("Logged in as Wholesale bank, going to Wholesale bank admin");
      history.push("/wholesale-bank-one/cbdc-admin");
    }
  };

  const refresh = async () => {
    const tokendata = await loginservice.getlatestuser();
    if (tokendata.token) {
      usetoken.saveToken(tokendata);
      setEntityinfo(tokendata.user);
    }
    getcentralhealth();
  };

  const displayassets = (theassets) => {
    return theassets.map((xx) => {
      return displayasset(xx);
    });
  };

  const displayasset = (asset) => {
    return (
      <>
        <div className="field text-2xl">
          <label>
            Asset :{" "}
            <span className="text-cyan-200">{asset.issue.issuetype}</span>
          </label>
        </div>
        <div className="field text-2xl">
          <label>
            {" "}
            Contract :
            <span className="text-cyan-200">
              {" "}
              {asset.issuerx.contracttxid}
            </span>{" "}
          </label>
        </div>
        <div className="field text-2xl">
          <label>
            {" "}
            Tokenissue :{" "}
            <span className="text-cyan-200">
              {asset.issuerx.tokenissuetxid}{" "}
            </span>
          </label>
        </div>
        <div className="field text-2xl">
          <label>
            {" "}
            Tokenid :{" "}
            <span className="text-cyan-200">{asset.issuerx.tokenid} </span>
          </label>
        </div>
        <div className="field text-2xl">
          <label>
            {" "}
            Receiver :{" "}
            <span className="text-cyan-200">
              {asset.receiverx.receiveraddress}{" "}
            </span>
          </label>
        </div>
      </>
    );
  };

  const getcentralhealth = async () => {
    var health = await loginservice.getcentralhealth();
    console.log(health);

    if (health.entityid) {
      setEntityid({ ...entityid, name: health.entityid });
    } else {
      setEntityid({ ...entityid, error: "Failed to load entity" });
      return;
    }

    if (health.user.email) {
      setUser({ ...user, name: health.user.email });
    } else {
      setUser({ ...user, error: "Failed to load user" });
      return;
    }

    if (health.centralentity.entityname) {
      setCentralentity({
        ...centralentity,
        name: health.centralentity.entityname,
      });
    } else {
      setCentralentity({ ...centralentity, error: "Failed to create entity" });
      return;
    }

    if (health.centralentityaccount.accountnumber) {
      setCentralentityaccount({
        ...centralentityaccount,
        name: health.centralentityaccount.accountnumber,
      });
    } else {
      setCentralentityaccount({
        ...centralentityaccount,
        error: "Failed to create entity account",
      });
      return;
    }

    if (health.entityaccounttokenuserx.tokenuseraddress) {
      if (health.entityaccounttokenuserx.tokenuseraddress.length > 1) {
        setEntityaccounttokenuserx({
          ...entityaccounttokenuserx,
          name: "Proper",
        });
      } else {
        setEntityaccounttokenuserx({
          ...entityaccounttokenuserx,
          name: "Not proper",
        });
      }
    } else {
      setEntityaccounttokenuserx({
        ...entityaccounttokenuserx,
        error: "Failed to create entity address",
      });
      return;
    }

    if (health.centralaccountnumber.accountnumber) {
      setCentralaccountnumber({
        ...centralaccountnumber,
        name: health.centralaccountnumber.accountnumber,
      });
    } else {
      setCentralaccountnumber({
        ...centralaccountnumber,
        error: "Failed to create central account",
      });
      return;
    }

    if (health.centralaccounttokenuserx.tokenuseraddress) {
      if (health.centralaccounttokenuserx.tokenuseraddress.length > 1) {
        setCentralaccounttokenuserx({
          ...centralaccounttokenuserx,
          name: "Proper",
        });
      } else {
        setCentralaccounttokenuserx({
          ...centralaccounttokenuserx,
          name: "Not proper",
        });
      }
    } else {
      setCentralaccounttokenuserx({
        ...centralaccounttokenuserx,
        error: "Failed to create central account address",
      });
      return;
    }

    setAssets(health.assetsets);
  };

  return (
    <div className="col-12 ">
      <div className="card p-fluid">
        <h5 className="text-3xl text-center uppercase my-3">
          CENTRAL BANK HEALTH
        </h5>

        <div className="field text-2xl mt-5">
          <label htmlFor="entiityid">
            Entity id: <span className="text-cyan-200">{entityid.name}</span>
          </label>
          <span className="text-pink-500">{entityid.error}</span>
        </div>

        <div className="field text-2xl">
          <label htmlFor="user">
            User :<span className="text-cyan-200"> {user.name}</span>
          </label>
          <span className="text-pink-500">{user.error}</span>
        </div>

        <div className="field text-2xl">
          <label htmlFor="centralentity">
            Centralentity :
            <span className="text-cyan-200"> {centralentity.name}</span>
          </label>
          <span className="text-pink-500">{centralentity.error}</span>
        </div>

        <div className="field text-2xl">
          <label htmlFor="centralentityaccount">
            Centralentityaccount :{" "}
            <span className="text-cyan-200">{centralentityaccount.name}</span>
          </label>
          <span className="text-pink-500">{centralentityaccount.error}</span>
        </div>

        <div className="field text-2xl">
          <label htmlFor="entityaccounttokenuserx">
            Centralentityaddress :
            <span className="text-cyan-200">
              {" "}
              {entityaccounttokenuserx.name}
            </span>
          </label>
          <span className="text-pink-500">{entityaccounttokenuserx.error}</span>
        </div>

        <div className="field text-2xl">
          <label htmlFor="centralaccount">
            Centralaccountnumber :
            <span className="text-cyan-200"> {centralaccountnumber.name}</span>
          </label>
          <span className="text-pink-500">{centralaccountnumber.error}</span>
        </div>

        <div className="field text-2xl">
          <label htmlFor="centralaccounttokenuserx">
            Centralaccountaddress :
            <span className="text-cyan-200">
              {" "}
              {centralaccounttokenuserx.name}
            </span>
          </label>
          <span className="text-pink-500">
            {centralaccounttokenuserx.error}
          </span>
        </div>

        {displayassets(assets)}

        <div className="flex  align-items-center  justify-content-between">
          {/* <label> */}
          <Button
            label="Refresh"
            onClick={() => refresh()}
            className=" m-3 text-2xl"
          />

          {/* </label> */}
        </div>
      </div>
    </div>
  );
}
