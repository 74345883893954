import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

function PvpTradehistory({
  transactionstraded,
  buy,
  sell,
  firstcurrency,
  secondcurrency,
  chosenpair,
  pairdata,
}) {
  const spriceBodyTemplate = (rowData) => {
    return <span className="text-cyan-200"> {rowData.price} </span>;
  };

  const sellamountBodyTemplate = (rowData) => {
    if (rowData.sellamount > 0) {
      return <span className="text-red-200">rowData.sellamount</span>;
    } else {
      if (pairdata.firstissuetype == rowData.sellissuetype) {
        return (
          <span className="text-green-200">
            rowData.buyamount * rowData.price).toFixed( pairdata.firstfraction /
          </span>
        );
      } else {
        return (
          <span className="text-green-200">
            rowData.buyamount * rowData.price).toFixed( pairdata.secondfraction
          </span>
        );
      }
    }
  };
  const buyamountBodyTemplate = (rowData) => {
    console.log(pairdata);
    console.log(rowData);
    if (rowData.buyamount > 0) {
      return <span className="text-green-200">rowData.buyamount</span>;
    } else {
      if (pairdata.firstissuetype == rowData.buyissuetype) {
        return (
          <span className="text-red-200">
            rowData.sellamount * rowData.price).toFixed( pairdata.firstfraction
          </span>
        );
      } else {
        return (
          <span className="text-red-200">
            rowData.sellamount * rowData.price).toFixed( pairdata.secondfraction
          </span>
        );
      }
    }
  };

  const buysymbolBodyTemplate = (rowData) => {
    return rowData.buyissuetype;
  };
  const sellsymbolBodyTemplate = (rowData) => {
    return rowData.sellissuetype;
  };
  const txidBodyTemplate = (rowData) => {
    var linktostas =
      "https://taalnet.whatsonchain.com/tx/" + rowData.transactionid;
    if (rowData.placedstatus == "completed") {
      return (
        <span className={`customer-badge status-${rowData.type}`}>
          <a href={linktostas} target="_blank">
            Link
          </a>
        </span>
      );
    } else {
      return rowData.transactionid;
    }
  };
  const statusBodyTemplate = (rowData) => {
    return rowData.placedstatus;
  };

  const dateBodyTemplate1 = (rowData) => {
    return (
      <>
        {new Intl.DateTimeFormat("en-US", {
          // year: 'numeric',
          // month: '2-digit',
          // day: '2-digit',
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }).format(rowData.createdAt)}
      </>
    );
  };

  return (
    <div className="grid table-demo">
      <div className="col-12">
        <div className="card border-1 border-100  ">
          <div className="flex ml-1">
            <DataTable
              value={transactionstraded}
              scrollable
              scrollHeight="350px"
              responsiveLayout="scroll"
              className="text-xl border-none w-full"
            >
              <Column
                header="Sell symbol"
                sortable
                body={sellsymbolBodyTemplate}
                rows={5}
                className="text-xl border-none w-5 lg:w-7"
              />
              <Column
                header="Sell amount"
                sortable
                body={sellamountBodyTemplate}
                rows={5}
                className="text-xl border-none w-4 lg:w-5"
              />

              <Column
                header="Buy symbol"
                sortable
                body={buysymbolBodyTemplate}
                rows={5}
                className="text-xl border-none w-5 lg:w-7"
              />

              <Column
                header="Buy amount"
                sortable
                body={buyamountBodyTemplate}
                rows={5}
                className="text-xl border-none w-4 lg:w-5"
              />

              <Column
                field="price"
                header="Price"
                sortable
                body={spriceBodyTemplate}
                rows={5}
                className="text-xl border-none w-4 lg:w-5"
              />
              <Column
                header="Transaction id"
                sortable
                body={txidBodyTemplate}
                rows={5}
                className="text-xl border-none w-5 lg:w-7"
              />
              <Column
                header="Status "
                sortable
                body={statusBodyTemplate}
                rows={5}
                className="text-xl border-none w-4 lg:w-5"
              />

              <Column
                // field="price"
                header="Time"
                sortable
                body={dateBodyTemplate1}
                className="text-xl border-none w-4 lg:w-5"
              />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PvpTradehistory;
