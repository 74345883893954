import { TabMenu } from "primereact/tabmenu";
import React, { useState } from "react";
import { Route, useHistory } from "react-router-dom";

import WBOTreaCBDCAssets from "./WBOTreasury/WBOTreaCBDCAssets";
import WBOTreasureBonds from "./WBOTreasury/WBOTreasureBonds";
import WTreauryBOT from "./WBOTreasury/WTreauryBOT";

const WBOTreasury = () => {
  const [activeIndex, setActiveIndex] = useState();
  const history = useHistory();
  const wizardItems = [
    {
      label: "CBDC ASSETS",
      icon: "pi pi-fw text-2xl text-yellow-500 pi-dollar",
      command: () => history.push("/wholesale-bank-one/treasury-dashboard"),
    },
  ];

  return (
    <div className="grid p-fluid p-5 d-flex justify-content-center">
      <div className="row-12  col-12 lg:col-9 p-5">
        <div className="card card-w-title border-1 border-100 h-full">
          {/* <h5 className="text-3xl p-4">Treasury Board</h5> */}
          <TabMenu
            model={wizardItems}
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
            readOnly={false}
            style={{ fontSize: "1.2rem" }}
          />
          <Route
            exact
            path={"/wholesale-bank-one/treasury-dashboard"}
            component={WBOTreaCBDCAssets}
          />
          <Route
            path={"/wholesale-bank-one/treasury-dashboard/balance-over-time"}
            component={WTreauryBOT}
          />
          <Route
            path={"/wholesale-bank-one/treasury-dashboard/bonds"}
            component={WBOTreasureBonds}
          />
        </div>
      </div>
    </div>
  );
};

export default WBOTreasury;
