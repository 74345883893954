import React, { useEffect, useState } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import { Chart } from "primereact/chart";
import { Satspertoken } from "../../App/Satspertoken";

import { WB01IssuanceService } from "./WB01IssuanceService";

const WBCBDCAssets = ({ transactionhappened }) => {
  const [loading, setLoading] = useState(1);
  const [data, setData] = useState();

  const [amount, setAmount] = useState([]);
  const [issuetype, setIssuetype] = useState([]);
  const [accountnumber, setAccountnumber] = useState("");

  var issuanceservice = new WB01IssuanceService();
  var satspertoken = new Satspertoken();

  const intervalID = setInterval(
    myCallback,
    1000,
    "Parameter 1",
    "Parameter 2"
  );

  function myCallback(a, b) {
    // Your code here
    // Parameters are purely optional.
    console.log(a);
    console.log(b);
    setLoading(2);
  }

  useEffect(() => {
    setLoading(1);
    if (accountnumber != "") {
      issuanceservice
        .getbalancefromaccountnumber(accountnumber)
        .then((res) => {});
    }
  }, [accountnumber]);

  useEffect(() => {
    if (amount.length > 0) {
      setLoading(2);
    } else {
      setLoading(1);
    }
  }, [amount]);

  useEffect(() => {
    //fetch data from api
    const fetchData = async () => {
      const dataset1 = [];
      const dataset2 = [];
      /*
      await fetch(url)
        .then((data) => {
          console.log("api data", data);
          const res = data.json();
          return res;
        })
	    */

      issuanceservice
        .getsubscribebankbalance()
        .then((res) => {
          setAccountnumber(res.accountnumber);
          console.log("ress", res.balance);
          for (const val of res.balance) {
            dataset1.push(satspertoken.wrap(val.satspertoken, val.amount));
            dataset2.push(val.issuetype);
          }
          setData({
            labels: dataset2,
            datasets: [
              {
                data: dataset1,
                backgroundColor: [
                  "#FFD700",
                  "#A38A00",
                  "#FFDE2E",
                  "#D1B000",
                  "#A38A00",
                ],
                hoverBackgroundColor: ["#FFE55C"],
              },
            ],
          });
          setAmount(dataset1);
          setIssuetype(dataset2);
          console.log("arrData", dataset1, dataset2);
        })
        .catch((e) => {
          console.log("error", e);
        });
    };
    fetchData();
  }, [transactionhappened]);
  const [lightOptions] = useState({
    indexAxis: "x",
    elements: {
      doughnut: {
        borderWidth: 2,
      },
    },
    plugins: {
      legend: {
        position: "right",
        labels: {
          color: "#eee",
        },
      },
      title: {
        display: true,
        text: "",
        color: "#eee",
      },
    },
  });

  return (
    <>
      <div className="col-12 ">
        <div className="grid p-fluid">
          <div className="col-12 md:col-6 ">
            <div className="card border-1 border-300 bg-gray-800  mt-3 card-w-title">
              <p className="border-bottom-1 pb-2 text-3xl">CBDC</p>
              {issuetype.map((cdata, index) => (
                <div className="list-disc" key={index}>
                  <li className="text-2xl pb-2">{cdata} </li>
                </div>
              ))}
            </div>
          </div>
          <div className="col-12 md:col-6 ">
            <div className="card border-1 border-300 bg-gray-800  mt-3 card-w-title">
              <p className="border-bottom-1 pb-2 text-3xl">Total assets</p>
              {amount.map((cdata, index) => (
                <div className="list-disc" key={index}>
                  <li className="text-2xl pb-2">{cdata} </li>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="  flex flex-column align-items-center ">
        {loading == 1 ? (
          <div>
            <ProgressSpinner
              style={{ width: "80px", height: "80px" }}
              // strokeWidth="8"
              fill="var(--surface-ground)"
              animationDuration=".8s"
            />
          </div>
        ) : (
          ""
        )}

        <Chart
          type="doughnut"
          data={data}
          options={lightOptions}
          className="w-9 md:w-7"
          style={{
            position: "relative",

            justifyContent: "center",
          }}
        />
      </div>
    </>
  );
};

export default WBCBDCAssets;
