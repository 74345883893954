import React, { useState, useEffect } from 'react';

import WBOAtomicSellOB from './AtomicOrderbook/WBOAtomicSellOB';
import { IssuanceServiceWBOB } from './AtomicOrderbook/issuanceServiceWBOB';
import { TabMenu } from 'primereact/tabmenu';
import WBOAtomicmarketDepth from './AtomicOrderbook/WBOAtomicmarketDepth';

import WBOAtomicVolume from './AtomicOrderbook/WBOAtomicVolume';
import WBOAtomicMatchHistory from './AtomicOrderbook/WBOAtomicMatchHistory';
import PvpTradehistory from './AtomicOrderbook/PvpTradehistory';
import PvpPlacedhistory from './AtomicOrderbook/PvpPlacedhistory';
import Pvpsellproposals from './AtomicOrderbook/Pvpsellproposals';
import Pvpbuyproposals from './AtomicOrderbook/Pvpbuyproposals';
import WBOAtomicBuyOrder from './AtomicOrderbook/WBOAtomicBuyOrder';
import PvpBuyorder from './AtomicOrderbook/PvpBuyorder';
import WBOAtomicOpensellOrder from './AtomicOrderbook/WBOAtomicOpensellOrder';
import WBOAtomicOpenbuyOrder from './AtomicOrderbook/WBOAtomicOpenbuyOrder';
import WBOAtomicCompleted from './AtomicOrderbook/WBOAtomicCompleted';
import WBOAtomicSellOrder from './AtomicOrderbook/WBOAtomicSellOrder';
import PvpSellorder from './AtomicOrderbook/PvpSellorder';
import WBOAtomicBuyOB from './AtomicOrderbook/WBOAtomicBuyOB';
import { LoginService } from '../devlogin/LoginService';

import { useToken } from '../App/useToken';
import * as _ from 'lodash';
import configData from '../App/Config.json';

const PvpAtomic = ({ data, setData }) => {
	const [currencies, setCurrencies] = useState([]);
	//const [testuser, setTestuser] = useState(theuser);
	const [pairs, setpairs] = useState('');
	const [firstcurrency, setFirstcurrency] = useState('');
	const [secondcurrency, setSecondcurrency] = useState('');
	const [chosenpair, setChosenpair] = useState('ABCD-DEFG');
	const [price, setprice] = useState([]);
	const [pairdata, setPairdata] = useState({});
	const [baseprice, setBaseprice] = useState(0);
	const [pastData, setpastData] = useState([]);
	const [transactions, setTransactions] = useState([]);
	const [transactionsplaced, setTransactionsplaced] = useState([]);
	const [transactionstraded, setTransactionstraded] = useState([]);
	const [sellData, setsellData] = useState([]);
	const [buyobData, setbuyobData] = useState([]);
	const [sellobData, setsellobData] = useState([]);
	const [allSellData, setallSellData] = useState([]);
	const [allBuyData, setallBuyData] = useState([]);

	const [orderplacedbuy, setOrderplacedbuy] = useState(false);
	const [orderplacedsell, setOrderplacedsell] = useState(false);
	const [activepvp, setActivepvp] = useState(0);
	const [activeone, setActiveone] = useState(0);
	const [activetwo, setActivetwo] = useState(0);
	const [activethree, setActivethree] = useState(0);
	const [activefour, setActivefour] = useState(0);
	const [activefive, setActivefive] = useState(0);
	const [activesix, setActivesix] = useState(0);
	const [fraction, setFraction] = useState({
		first: 1,
		second: 2,
	});
	// const text = data.assetid.label;

	const usetoken = new useToken();
	const [user, setUser] = useState(usetoken.getUser());
	const url = configData.baseurl;
	const apiurl = url + '/atomicexchange/getpairs';
	let ratesURL = `https://api.exchangerate.host/latest?base=GBP&symbols=INR`;

	const loginservice = new LoginService();

	const issuanceservice = new IssuanceServiceWBOB();

	useEffect(() => {
		//  refresh();

		//    setUser(issuanceservice.getuser());
		console.log(user);
		//setChosenpair('CREA_CINR-CREA_USD');
	}, []);

	/*
  const refresh = async () => {

    const tokendata = await loginservice.getlatestuser();
      console.log(tokendata.user);
      if(tokendata.token) {
      //usetoken.saveToken(tokendata);
      const theuser = {
	      centralaccountnumber: tokendata.user.centralaccountnumber,
            entityaccountnumber: tokendata.user.entityaccountnumber,
            subcentralaccountnumber: tokendata.user.subcentralaccountnumber,
      };
      setTestuser(theuser );
      console.log(testuser);
      }
  }

*/
	useEffect(() => {
		//    setpairs('Digital_USD-Digital_INR');
		//    setpairs('CREA_CINR-CREA_CUSD');
		// let ratesURL = `https://api.exchangerate.host/latest?base=USD&symbols=INR`;
		const fetchRatesData = async () => {
			let ratesArr = [];
			await fetch(ratesURL)
				.then((res) => res.json())
				.then((data) => (ratesArr = data.rates.INR));

			setprice(ratesArr);
			console.log(ratesArr);
		};

		fetchRatesData();

		let pairs = [];

		const apiCall = async () => {
			await fetch(apiurl)
				.then((res) => res.json())
				.then((data) => (pairs = data));

			let filtered = pairs.sort((a, b) => {
				if (a < b) {
					return -1;
				}
				if (a > b) {
					return 1;
				}
				return 0;
			});

			setCurrencies(filtered);
			setPairdata(filtered[0]);
			let currencypair = pairs.map((cur, idx) => {
				return cur.pairname;
			});
			setChosenpair(currencypair[0]);
			setFirstcurrency(filtered[0].firstissuetype);
			setSecondcurrency(filtered[0].secondissuetype);
			setFraction({
				first: filtered[0].firstfraction,
				second: filtered[0].secondfraction,
			});
			setBaseprice(filtered[0].baseprice);

			// let amount = pairs.map((cur, idx) => {
			//   return cur.count;
			// });
			// setprice(amount);
		};

		apiCall();
	}, []);

	useEffect(() => {
		console.log(chosenpair);

		/*
		 */
		/*

*/

		const fetchBuyOrderbookData = async () => {
			let buyobURL = url + `/atomicexchange/getbuyorders/${chosenpair}`;
			let dataArr = [];
			let ordersArr = [];
			await fetch(buyobURL)
				.then((res) => {
					return res.json();
					/*
		console.log(await res.json());
		console.log(res.body);
		console.log(JSON.parse(res.body));
		console.log(JSON.stringify(res.body));
		res.body?.json() }
		*/
				})
				.then((data) => {
					console.log(data);
					if (data) {
						//  data = JSON.parse(data);
						dataArr = data.buysideorders;
					}
				})
				.catch((err) => {
					console.log(err);
				});

			console.log(dataArr);
			var last = _.sortBy(dataArr, 'updatedAt').reverse();

			console.log(last);

			setbuyobData(last);
			setOrderplacedbuy(false);
		};

		fetchBuyOrderbookData();

		const fetchSellOrderbookData = async () => {
			let sellobURL = url + `/atomicexchange/getsellorders/${chosenpair}`;
			let ordersArr = [];
			let dataArr = [];
			await fetch(sellobURL)
				.then((res) => {
					return res.json();
				})
				.then((data) =>
					data ? (dataArr = data.sellsideorders) : (dataArr = [])
				);

			var last = _.sortBy(dataArr, 'updatedAt').reverse();

			console.log(dataArr);
			console.log(last);

			setsellobData(last);
			setOrderplacedsell(false);
		};

		fetchSellOrderbookData();

		const fetchOrderstradedData = async () => {
			let ordersURL = url + `/atomicexchange/getorders/${chosenpair}`;
			let transactionsURL =
				url +
				'/atomicexchange/getordertradedtransactions/' +
				user.subcentralaccountnumber;
			let ordersArr = [];
			await fetch(transactionsURL)
				.then((res) => res.text())
				.then((data) => (ordersArr = data));

			const forsorting = _.uniqBy(JSON.parse(ordersArr), 'transactionid'); //json.transactions;
			var last = _.sortBy(forsorting, 'updatedAt'); //.reverse();
			/*
      const sorted1 = ordersArr;
      const last1 = sorted1
        .sort((a, b) => {
          return a > b ? -1 : 1;
        })
        .slice(0, 10); */
			// const sorted2 = ordersArr.sellsideorders;
			// const last2 = sorted2.slice(-10).sort((a, b) => {
			//   return a > b ? 1 : -1;
			// });
			// const last3 = last1.concat(last2);
			setTransactionstraded(last);
			// setallSellData(last2);
			// console.log(last3);
		};

		fetchOrderstradedData();

		const fetchOrdersplacedData = async () => {
			let ordersURL = url + `/atomicexchange/getorders/${chosenpair}`;
			let transactionsURL =
				url +
				'/atomicexchange/getorderplacedtransactions/' +
				user.subcentralaccountnumber;
			let ordersArr = [];
			await fetch(transactionsURL)
				.then((res) => res.text())
				.then((data) => (ordersArr = data));

			const forsorting = _.uniqBy(JSON.parse(ordersArr), 'transactionid'); //json.transactions;
			var last = _.sortBy(forsorting, 'updatedAt').reverse();
			/*
      const sorted1 = ordersArr;
      const last1 = sorted1
        .sort((a, b) => {
          return a > b ? -1 : 1;
        })
        .slice(0, 10);
	    */
			// const sorted2 = ordersArr.sellsideorders;
			// const last2 = sorted2.slice(-10).sort((a, b) => {
			//   return a > b ? 1 : -1;
			// });
			// const last3 = last1.concat(last2);
			setTransactionsplaced(last);
			// setallSellData(last2);
			// console.log(last3);
		};

		fetchOrdersplacedData();

		const fetchOrdersData = async () => {
			let ordersURL = url + `/atomicexchange/getorders/${chosenpair}`;
			let transactionsURL =
				url +
				'/atomicexchange/getordertransactions/' +
				user.subcentralaccountnumber;
			let ordersArr = [];
			await fetch(transactionsURL)
				.then((res) => res.text())
				.then((data) => {
					ordersArr = data;
					//console.log(data);
				});
			const sorted1 = ordersArr;
			//console.log(sorted1);

			//         const forsorting = _.uniqBy(json.transactions, 'transactionid'); //json.transactions;
			var last = _.sortBy(JSON.parse(sorted1), 'updatedAt').reverse();

			if (last.length > 10) {
				setTransactions(last.slice(0, 10));
			} else {
				setTransactions(last);
			}
		};

		fetchOrdersData();
	}, [chosenpair, orderplacedbuy, orderplacedsell]);

	const handleSelect = (e) => {
		//setpairs(e);
		console.log(e.target.value);
		var thepair = e.target.value;
		setChosenpair(e.target.value);
		var selected = currencies.filter((xx) => {
			if (thepair == xx.pairname) {
				return true;
			}
		});

		//console.log(selected[0]);
		if (selected[0]) {
			setPairdata(selected[0]);

			setFirstcurrency(selected[0].firstissuetype);
			setSecondcurrency(selected[0].secondissuetype);
			//setprice(selected[0].baseprice);
			setFraction({
				first: selected[0].firstfraction, //filtered
				second: selected[0].secondfraction, //filtered
			});
			setBaseprice(selected[0].baseprice);
		}
	};

	const DisplayOne = () => {
		/*
    if (activeone === 0) {
      return <WBOAtomicmarketDepth buy={pastData} sell={sellData} />;
    } else if (activeone === 1) {
      return <WBOAtomicVolume buy={pastData} sell={sellData} />;
    }
*/
	};
	const DisplayTwo = () => {
		if (activetwo === 0) {
			return (
				<PvpPlacedhistory
					transactionsplaced={transactionsplaced}
					buy={pastData}
					sell={sellData}
					firstcurrency={firstcurrency}
					secondcurrency={secondcurrency}
					chosenpair={chosenpair}
				/>
			);
		}
		if (activetwo === 1) {
			return (
				<PvpTradehistory
					transactionstraded={transactionstraded}
					buy={pastData}
					sell={sellData}
					firstcurrency={firstcurrency}
					secondcurrency={secondcurrency}
					chosenpair={chosenpair}
					pairdata={pairdata}
				/>
			);
		}
	};

	const DisplayPvp = () => {
		if (activepvp === 0) {
			return (
				<Pvpbuyproposals
					user={user}
					buy={buyobData}
					sell={sellobData}
					firstcurrency={firstcurrency}
					secondcurrency={secondcurrency}
					chosenpair={chosenpair}
					setOrderplacedbuy={setOrderplacedbuy}
					setOrderplacedsell={setOrderplacedsell}
				/>
			);
		}
		if (activepvp === 1) {
			return (
				<Pvpsellproposals
					user={user}
					buy={buyobData}
					sell={sellobData}
					firstcurrency={firstcurrency}
					secondcurrency={secondcurrency}
					chosenpair={chosenpair}
					setOrderplacedbuy={setOrderplacedbuy}
					setOrderplacedsell={setOrderplacedsell}
				/>
			);
		}
	};

	const DisplayThree = () => {
		if (activethree === 0) {
			return <WBOAtomicBuyOB data={buyobData} chosenpair={chosenpair} />;
		} else if (activethree === 1) {
			return <WBOAtomicSellOB data={sellobData} chosenpair={chosenpair} />;
		}
	};

	const DisplayFour = () => {
		return <WBOAtomicCompleted data={allBuyData} chosenpair={chosenpair} />;
		/*
    if (activefour === 0) {
      return <WBOAtomicOpenbuyOrder data={pastData} />;
    } else if (activefour === 1) {
      return <WBOAtomicOpensellOrder data={pastData} />;
    } else if (activefour === 2) {
      return <WBOAtomicCompleted data={allBuyData} />;
    }
*/
	};

	const DisplayFive = () => {
		if (activefive === 0) {
			return (
				<PvpBuyorder
					user={user}
					price={price}
					fraction={fraction}
					firstcurrency={firstcurrency}
					secondcurrency={secondcurrency}
					chosenpair={chosenpair}
					setOrderplacedbuy={setOrderplacedbuy}
				/>
			);
		} else if (activefive === 1) {
			return (
				<PvpSellorder
					user={user}
					price={price}
					fraction={fraction}
					firstcurrency={firstcurrency}
					secondcurrency={secondcurrency}
					chosenpair={chosenpair}
					setOrderplacedsell={setOrderplacedsell}
				/>
			);
		}
	};

	const wizardItems = [
		{
			label: 'MARKET DEPTH',
			icon: 'pi pi-fw text-2xl text-yellow-500 pi-arrow-right-arrow-left',
		},
		{
			label: 'ORDER VOLUME',
			icon: 'pi pi-fw text-2xl text-yellow-500 pi-upload',
		},
	];

	const dataItems = [
		{
			label: 'PLACED ',
			icon: 'pi pi-fw text-2xl text-yellow-500 pi-box',
		},
		{
			label: 'BI-SWAPS ',
			icon: 'pi pi-fw text-2xl text-yellow-500 pi-sort-alt-slash',
		},
	];

	const dataStates = [
		{
			label: 'BUY ORDERS',
			icon: 'pi text-2xl text-yellow-500 pi-fw pi-sort-alt',
		},
		{
			label: 'SELL ORDERS',
			icon: 'pi text-2xl text-yellow-500 pi-fw pi-user',
		},
	];

	const dataOrders = [
		{
			label: 'COMPLETED ORDERS',
			icon: 'pi text-2xl text-yellow-500 pi-fw pi-user',
		},
	];

	const buySell = [
		{
			label: 'BUY',
			icon: 'pi text-2xl text-green-500 pi-fw pi-sort-numeric-down',
		},
		{
			label: 'SELL',
			icon: 'pi text-2xl text-pink-500 pi-fw pi-sort-numeric-up',
		},
	];
	const pvpwizz = [
		{
			label: 'Buy PVP',
			icon: 'pi text-2xl text-yellow-500 pi-fw pi-sort-amount-down',
		},
		{
			label: 'Sell PVP',
			icon: 'pi text-2xl text-yellow-500  pi-fw pi-sort-amount-up',
		},
	];

	return (
		<div class="grid px-4">
			<div class="col-12 ">
				<div class="grid gap-4 justify-content-around">
					<div class="card card-w-title lg:col-8 col-12 border-1 border-100 ">
						<TabMenu
							model={pvpwizz}
							id={wizardItems.id}
							activeIndex={activepvp}
							onTabChange={(e) => setActivepvp(e.index)}
							style={{ fontSize: '1.2rem' }}
						/>
						{DisplayPvp()}
					</div>

					<div class="card lg:col-3 col-12 border-1 border-100">
						<div class="flex border-1 border-300 bg-gray-800 card justify-content-between">
							{
								<select
									name="currency"
									className="px-4 bg-blue-600 border-round-xl border-none font-bold text-2xl "
									value={chosenpair}
									onChange={(e) => handleSelect(e)}
								>
									{currencies.map((cur, idx) => {
										return (
											<option
												key={idx}
												className="p-1 bg-blue-600 border-round-lg  font-bold text-2xl "
												value={cur.pairname}
											>
												{cur.pairname}
											</option>
										);
									})}
								</select>
							}

							{
								<h2 className="p-2 bg-blue-600 border-round-xl border-none font-bold text-2xl ">
									{baseprice}
								</h2>
							}
						</div>
						<h2 className=" py-4 text-center">
							P2P{' '}
							<strong className="text-normal text-yellow-500">Bi-SWAP</strong>
						</h2>
						<p className="text-center text-xl pb-4 border-bottom-1">
							Use Intrasettle P2P Bi-SWAP allows swap of currencies between
							banks. It's safe and hassle free!
						</p>
						<p class="text-center text-xl text-yellow-500">How it works</p>
						<div class="text-xl pl-6">
							<li>Choose the participant bank</li>
							<li>Propose swap </li>
							<li>The participant accepts the proposal </li>
							<li>The swap happens atomically </li>
						</div>
					</div>

					<div className="card lg:col-8 col-12 border-1 border-100">
						<TabMenu
							model={dataItems}
							id={dataItems.id}
							activeIndex={activetwo}
							onTabChange={(e) => setActivetwo(e.index)}
							style={{ fontSize: '1.2rem' }}
						/>

						{DisplayTwo()}
					</div>
					<div className="card lg:col-3 col-12 border-1 border-100">
						<TabMenu
							model={buySell}
							id={buySell.id}
							activeIndex={activefive}
							onTabChange={(e) => setActivefive(e.index)}
							style={{ fontSize: '1.2rem' }}
						/>

						{DisplayFive()}
					</div>
				</div>
			</div>
		</div>
	);
};

export default PvpAtomic;
