import React, { useState, useEffect } from "react";

import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { IssuanceServiceRIC } from "./IssuanceServiceRIC";

import { Column } from "primereact/column";

function IssueRequestCB() {
  const [modifiedbuy, setModifiedbuy] = useState([]);
  const [consideredsell, setConsideredsell] = useState([]);
  const [issuance, setIssuance] = useState([]);

  const issuanceService = new IssuanceServiceRIC();

  const getdata = async () => {
    issuanceService.getissuancerequests().then((data) => {
      var xx = data.map(function (value) {
        return {
          label: value.issuetype,
          id: value.id,
          centralentityid: value.centralentityid,
          requestentityid: value.requestentityid,
          requestacountnumber: value.requestacountnumber,
          assetid: value.assetid,
          satspertoken: value.satspertoken,
          issuetype: value.issuetype,
          amount: value.amount,
          outcome: value.outcome,
          message: value.message,
        };
      });

      setIssuance(xx);
    });
  };

  useEffect(() => {
    getdata();
  }, []);

  const issueraccept = async (data) => {
    console.log("sending");
    var data = {
      id: data.id,
      outcome: "accepted",
    };
    var ret = await issuanceService.updateissuancerequest(data);
    console.log(ret);
    getdata();
  };

  const issuerrejected = async (data) => {
    console.log("sending");
    var data = {
      id: data.id,
      outcome: "rejected",
    };
    var ret = await issuanceService.updateissuancerequest(data);
    console.log(ret);
    getdata();
  };

  const issuetypBodyTemplate = (rowData) => {
    return <span className="text-red-400"> {rowData.issuetype} </span>;
  };

  const messagetemplate = (rowData) => {
    return rowData.message;
  };

  const executetemplate = (rowData) => {
    console.log(rowData.outcome);

    if (rowData.outcome == "") {
      return (
        <div className="flex align-items-center gap-2">
          <Button
            label="Accept"
            onClick={() => issueraccept(rowData)}
            className="text-2xl p-button-success"
          />
          <Button
            label="Reject"
            onClick={() => issuerrejected(rowData)}
            className="text-2xl p-button-danger"
          />
        </div>
      );
    } else {
      return <div className="flex align-items-center gap-2">Processed</div>;
    }
  };

  const samountBodyTemplate = (rowData) => {
    return rowData.amount;
  };
  const dateBodyTemplate1 = (rowData) => {
    return (
      <>
        {new Intl.DateTimeFormat("en-US", {
          // year: 'numeric',
          // month: '2-digit',
          // day: '2-digit',
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }).format(rowData.createdAt)}
      </>
    );
  };

  const idBodyTemplate = (rowData) => {
    return rowData.requestentityid;
  };

  return (
    <div className="grid table-demo">
      <div className="col-12">
        <div className="card border-1 border-100  ">
          <div className="flex ml-1">
            <DataTable
              value={issuance}
              scrollable
              scrollHeight="350px"
              responsiveLayout="scroll"
              className="text-xl border-none w-full"
            >
              <Column
                field="issuetype"
                header="Asset"
                sortable
                // style={{ width: "55%" }}
                body={issuetypBodyTemplate}
                rows={5}
                className="text-xl border-none w-5 lg:w-7"
              />

              <Column
                field="amount"
                header="Volume"
                sortable
                // style={{ width: "35%" }}
                body={samountBodyTemplate}
                rows={5}
                className="text-xl border-none w-4 lg:w-5"
              />
              <Column
                // field="price"
                header="ID"
                sortable
                // style={{ width: "35%" }}
                body={idBodyTemplate}
                className="text-xl border-none w-4 lg:w-5"
              />

              <Column
                header="Message"
                // style={{ width: "200px" }}
                body={messagetemplate}
                className="text-xl border-none w-15rem lg:w-20rem"
              />

              <Column
                header="Option"
                // style={{ width: "200px" }}
                body={executetemplate}
                className="text-xl border-none w-15rem lg:w-20rem"
              />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IssueRequestCB;
